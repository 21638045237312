import React, { useContext, useEffect } from "react"

import Layout from "../layouts/main"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import { getUser } from "../utils"
import StoreContext from "../context/storeContext"

export default function Exit() {
  const { user } = useContext(StoreContext)

  useEffect(() => {
    const role = getUser()?.role

    if (typeof window !== "undefined" && role && role !== "user") {
      navigate(`/app/dashboard`)
    }
  }, [user])

  return (
    <Layout>
      <Seo title="Seychelles Aluminium Windows and Doors Specialist" description="Seychelles aluminium windows and doors specialist since 2001. We have a wide selection of products: Casement, Sliding and folding frames with Clear, Bronze, Tinted, Mirror and Laminated Safely Glass" />
      <h1 className="mt-28 text-lg text-gray-900 font-medium text-center">Website reserved to VAB staff.</h1>
      <p className="mt-2 text-lg text-gray-600 font-medium text-center">Please contact the administrator.</p>
    </Layout>
  )
}
